import React from 'react';
import PropTypes from 'prop-types';

const Section = props => {
  const { children, className } = props;
  return <div className={`block py-10 md:py-20 lg:py-30 ${className || ''}`}>{children}</div>;
};

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

Section.defaultProps = {
  className: ''
};

export default Section;
