import React from 'react';
import Feature1 from 'assets/main/feature1.svg';
import Feature2 from 'assets/main/feature2.svg';
import Feature3 from 'assets/main/feature3.svg';

import Section from 'components/Section';

const Features = () => (
  <Section>
    <main className="px-4 sm:px-6 lg:px-8">
      {/* <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"> */}
      <h1 className="mx-auto text-4xl tracking-tight font-bold text-gray-700 sm:text-3xl md:text-4xl text-center">
        <span className="block lg:inline">What can I do with KopiRun?</span>
      </h1>
      <div className="grid gap-8 row-gap-8 lg:grid-cols-3">
        <div className="text-center">
          <img alt="Step 1 create kopirun" className="mt-10 w-64 mx-auto" src={Feature1} />
          <h6 className="mb-2 font-semibold leading-5 mt-10">Earn Some Money</h6>
          <p className="max-w-md mb-3 text-gray-900 mx-auto">
            Create a group buy and charge a small fee per order.
          </p>
        </div>
        <div className="text-center">
          <img alt="Step 2 join other kopiruns" className="mt-10 w-64 mx-auto" src={Feature2} />
          <h6 className="mb-2 font-semibold leading-5 mt-10">Save on Delivery Fees</h6>
          <p className="max-w-md mb-3 text-gray-900 mx-auto">
            Place orders for group buys near you and save on steep delivery fees.
          </p>
        </div>
        <div className="text-center">
          <img alt="Step 3 chat with others" className="mt-10 w-64 mx-auto" src={Feature3} />
          <h6 className="mb-2 font-semibold leading-5 mt-10">Chat with others</h6>
          <p className="max-w-md mb-3 text-gray-900 mx-auto">
            Chat with others in the app to organize payment and collection. We do not reveal your
            phone number.
          </p>
        </div>
      </div>
      {/* </div> */}
    </main>
  </Section>
);

export default Features;
